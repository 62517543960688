import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const Layout = lazy(() => import('@/pages/layout.tsx'))
const RequireAuth = lazy(() => import('@/pages/require-auth.tsx'))
const SignIn = lazy(() => import('@/pages/sign-in/sign-in.tsx'))
const Dashboard = lazy(() => import('@/pages/dashboard/dashboard.tsx'))
const Users = lazy(() => import('@/pages/users/users.tsx'))
const Companies = lazy(() => import('@/pages/companies/companies.tsx'))
const Faq = lazy(() => import('@/pages/faq/faq.tsx'))
const Issues = lazy(() => import('@/pages/issues/issues.tsx'))
const Invoices = lazy(() => import('@/pages/invoices/invoices.tsx'))
const Subscriptions = lazy(() => import('@/pages/subscriptions/subscriptions.tsx'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="sign-in" element={<SignIn />} />

        <Route element={<RequireAuth />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/issues" element={<Issues />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
        </Route>
      </Route>
    </Routes>
  )
}

export { AppRoutes }
